<template>
  <section class="container how-connect">
    <h2 class="how-connect__title headline-2">
      Как происходит подключение к {{ provider?.provider.name }}
    </h2>
    <div class="how-connect__wrap d-flex justify-between">
      <div
        data-test="first-installation-step"
        class="how-connect__item d-flex flex-column align-center"
      >
        <div class="how-connect__item-header d-flex">
          <div class="d-flex align-center justify-center how-connect__item-img">
            <img
              loading="lazy"
              src="@/assets/images/useful/p_step1.svg"
              alt="step"
            >
          </div>
          <p class="how-connect__item-title fwm">
            Шаг 1
          </p>
        </div>
        <p class="how-connect__item-descr">
          Вы оставляете
          <button
            v-if="!segmentationActive"
            data-test="order-button-installation-step"
            @click="showDialogCallPartner"
          >
            заявку
          </button>
          <template v-else>
            заявку
          </template>
          на сайте
          <template v-if="!segmentationActive && !hideNumber && provider?.provider.id !== 6">
            или звоните по телефону
            <a
              v-if="phone"
              data-test="installation-step-phone"
              class="secondary-dark--text text-no-wrap"
              :href="'tel:' + callNumber.call_number"
              @click.prevent="callClick(callNumber.call_number, 'SHOW_CONNECT')"
            >{{ callNumber.display_number }}</a>
          </template>
        </p>
      </div>
      <div class="how-connect__item d-flex flex-column align-center">
        <div class="how-connect__item-header d-flex">
          <div class="d-flex align-center justify-center how-connect__item-img">
            <img
              loading="lazy"
              src="@/assets/images/useful/p_step2.svg"
              alt="step"
            >
          </div>
          <p class="how-connect__item-title fwm">
            Шаг 2
          </p>
        </div>
        <p class="how-connect__item-descr">
          Наш специалист связывается с вами для уточнения деталей, согласования
          даты и времени выезда мастера
        </p>
      </div>
      <div class="how-connect__item d-flex flex-column align-center">
        <div class="how-connect__item-header d-flex">
          <div class="d-flex align-center justify-center how-connect__item-img">
            <img
              loading="lazy"
              src="@/assets/images/useful/p_step3.svg"
              alt="step"
            >
          </div>
          <p class="how-connect__item-title fwm">
            Шаг 3
          </p>
        </div>
        <p class="how-connect__item-descr">
          В назначенное время монтажник подключает интернет и настраивает
          оборудование
        </p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'
import { createInteraction } from '~/composible/createInteraction'
import { useCities } from '~/store/cites/cities.store'

defineProps({
  callNumber: {},
})

const mainStore = useMainStore()
const ctx = useNuxtApp()
const cityStore = useCities()
const provider = computed(() => mainStore.getDomainConfig)
const phone = computed(() => mainStore.getCallNumber?.connect)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const hideNumber = computed(() => mainStore.getHideNumber)
const interactionsSended = computed(() => mainStore.getInteractionsSended)
const houseUrl = computed(() => cityStore.getHouseUrl)

const showDialogCallPartner = () => {
  if (houseUrl.value) ctx.$event('callDialog', 'Оставить заявку')
  else ctx.$event('callDialogPartner', true)
}

const { cI } = createInteraction()

const callClick = (number: string, type: string) => {
  window.open('tel:' + number, '_self')
  if (!interactionsSended.value) {
    mainStore.$patch({
      interactionsSended: true,
    })
    cI(
      true,
      undefined,
      undefined,
      { request_type: type },
      undefined,
      undefined,
    )
  }
}
</script>

<style scoped lang="scss">
.how-connect {
  padding-top: 40px;
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 24px;
  }
  &__title {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
  }

  &__wrap {
    @media (max-width: getBreakpoint(desktop)) {
      flex-direction: column;
    }
  }

  &__item {
    padding: 24px;
    border-radius: 20px;
    flex: 0 0 calc(33.333% - 16px);
    background-color: color(gray-p);
    &-img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: var(--mainColor);
      color: var(--font_btn);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 8px;
      @media (max-width: getBreakpoint(desktop)) {
        margin-right: 8px;
        margin-bottom: 0;
      }
      @media (max-width: getBreakpoint(tablet)) {
        width: 50px;
        height: 50px;
        &:deep(svg) {
          width: 30px;
          height: 30px;
        }
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 40px;
        height: 40px;
        &:deep(svg) {
          width: 28px;
          height: 28px;
        }
      }
    }
    &-header {
      flex-direction: column;
      @media (max-width: getBreakpoint(desktop)) {
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 12px;
      }
    }
    &-title {
      font-size: fontSize(headline-7);
      line-height: 1.4;
      margin-bottom: 12px;
      @media (max-width: getBreakpoint(desktop)) {
        margin-bottom: 0;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 19px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(headline-8);
      }
    }
    &-descr {
      font-size: fontSize(headline-8);
      line-height: 1.4;
      text-align: center;
      button,
      a {
        color: color(secondary-dark);
        transition: color 0.24s;
        &:hover {
          color: color(primary);
        }
      }
      @media (max-width: getBreakpoint(tablet)) {
        font-size: fontSize(title);
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        font-size: 15px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        font-size: fontSize(paragraph);
      }
    }
    @media (max-width: getBreakpoint(desktop)) {
      margin-bottom: 16px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding: 16px;
    }
  }
}
</style>
